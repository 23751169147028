import Vue from 'vue';
import store from '@/store/index';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

Vue.use(VueRouter);

const routes = [
  {
    component: () => import(/* webpackChunkName: "AccountDisabled" */ '@/views/AccountDisabled.vue'),
    meta: {
      isPublic: true,
    },
    name: 'AccountDisabled',
    path: '/account-disabled',
  },
  {
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Landing.vue'),
    path: '/',
    name: 'Landing',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    path: '/home',
    name: 'Home',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "AddessSearchInfo" */ '@/views/AddessSearchInfo.vue'),
    path: '/address-search-information',
    name: 'AddessSearchInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "TripCostInfo" */ '@/views/TripCostInfo.vue'),
    path: '/trip-cost-information',
    name: 'TripCostInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "MapLayersInfo" */ '@/views/MapLayersInfo.vue'),
    path: '/map-layers-information',
    name: 'MapLayersInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "RouteInfo" */ '@/views/RouteInfo.vue'),
    path: '/route-information',
    name: 'RouteInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "DeliveryInfo" */ '@/views/DeliveryInfo.vue'),
    path: '/delivery-information',
    name: 'DeliveryInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "MapBackgroundInfo" */ '@/views/MapBackgroundInfo.vue'),
    path: '/map-background-information',
    name: 'MapBackgroundInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "JourneyPlannerInfo" */ '@/views/JourneyPlannerInfo.vue'),
    path: '/journey-planner-information',
    name: 'JourneyPlannerInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "TravelBandsInfo" */ '@/views/TravelBandsInfo.vue'),
    path: '/travel-bands-information',
    name: 'TravelBandsInformation',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "WeatherInfo" */ '@/views/WeatherInfo.vue'),
    path: '/weather-information',
    name: 'WeatherInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "CoordinateSearchInfo" */ '@/views/CoordinateSearchInfo.vue'),
    path: '/coordinate-search-information',
    name: 'CoordinateSearchInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "MapToolsInfo" */ '@/views/MapToolsInfo.vue'),
    path: '/map-tools-information',
    name: 'MapToolsInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "HeatMapLayerInfo" */ '@/views/HeatMapLayerInfo.vue'),
    path: '/heat-map-layer-information',
    name: 'HeatMapLayerInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "ThematicMapsLayerInfo" */ '@/views/ThematicMapsLayerInfo.vue'),
    path: '/thematic-maps-layer-information',
    name: 'ThematicMapsLayerInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "AddressDetailsInfo" */ '@/views/PlaceDetailsInfo.vue'),
    path: '/place-details-information',
    name: 'PlaceDetailsInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "PropertyOwnershipDeedsInfo" */ '@/views/PropertyOwnershipDeedsInfo.vue'),
    path: '/property-ownership-deeds-information',
    name: 'PropertyOwnershipDeedsInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "EnterpriseDetailsInfo" */ '@/views/EnterpriseDetailsInfo.vue'),
    path: '/enterprise-details-information',
    name: 'EnterpriseDetailsInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "PropertyAnalysisInfo" */ '@/views/PropertyAnalysisInfo.vue'),
    path: '/property-analysis-information',
    name: 'PropertyAnalysisInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "PopulationSearchInfo" */ '@/views/PopulationSearchInfo.vue'),
    path: '/population-search-information',
    name: 'PopulationSearchInfo',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "ContactUs" */ '@/views/ContactUs.vue'),
    path: '/contact-us',
    name: 'ContactUs',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "EnterpriseSearch" */ '@/views/EnterpriseSearch.vue'),
    path: '/enterprise-search',
    name: 'EnterpriseSearch',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "PropertySearch" */ '@/views/PropertySearch.vue'),
    path: '/property-search',
    name: 'PropertySearch',
    meta: {
      isPublic: true,
    },
  },
  {
    component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
    meta: {
      isPublic: true,
    },
    name: 'Error',
    path: '/error',
  },
  {
    component: () => import(/* webpackChunkName: "NotAuthorized" */ '@/views/NotAuthorized.vue'),
    meta: {
      isPublic: true,
    },
    name: 'NotAuthorized',
    path: '/not-authorized',
  },
  {
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
    meta: {
      isPublic: true,
    },
    name: 'NotFound',
    path: '/not-found',
  },
  {
    component: () => import(/* webpackChunkName: "OidcCallback" */ '@/views/OidcCallback.vue'),
    meta: {
      isPublic: true,
    },
    name: 'OidcCallback',
    path: '/oidc-callback',
  },
  {
    meta: {
      isPublic: true,
    },
    component: () => import(/* webpackChunkName: "OidcSilentRenew" */ '@/views/OidcSilentRenew.vue'),
    name: 'OidcSilentRenew',
    path: '/oidc-silent-renew',
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
