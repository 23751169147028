import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

import Axios from 'axios';

const MUTATION_SET_LOADING = 'SetLoading';
const MUTATION_SET_LOADATTEMPTED = 'SetLoadAttempted';
const MUTATION_SET_ROLE = 'SetRole';

const STATEVAR_LOADATTEMPTED = 'loadAttempted';
const STATEVAR_LOADING = 'loading';
const STATEVAR_ROLE = 'role';

const actions = {
  Load: (context) => new Promise((resolve, reject) => {
    context.commit(MUTATION_SET_LOADING, true);
    context.commit(MUTATION_SET_LOADATTEMPTED, true);

    Axios
      .get(`${process.env.VUE_APP_ROOT_API}/proxies/users/api/auth`)
      .then((response) => {
        if (response.data.code === 200) {
          const { role } = response.data.result;
          context.commit(MUTATION_SET_ROLE, role);
          resolve();
        }
      })
      .catch((error) => {
        reject(error.response);
      })
      .finally(() => {
        context.commit(MUTATION_SET_LOADING, false);
      });
  }),
};

const getters = {
  LoadAttempted: GettersUtility.get(STATEVAR_LOADATTEMPTED),
  Loading: GettersUtility.get(STATEVAR_LOADING),
  Role: GettersUtility.get(STATEVAR_ROLE),
};

const mutations = {
  SetLoadAttempted: MutationsUtility.set(STATEVAR_LOADATTEMPTED),
  SetLoading: MutationsUtility.set(STATEVAR_LOADING),
  SetRole: MutationsUtility.set(STATEVAR_ROLE),
};

const state = {
  loadAttempted: false,
  loading: false,
  role: null,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
