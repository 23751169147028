<template>
  <div>
    <app-header v-if="!onLandingPage" />
    <router-view :class="landingPageStyle" />
    <app-footer :showAppTools="showAppTools" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapGetters({
      oidcAccessToken: 'oidcStore/oidcAccessToken',
      oidcAuthenticationIsChecked: 'oidcStore/oidcAuthenticationIsChecked',
      oidcIsAuthenticated: 'oidcStore/oidcIsAuthenticated',
      oidcUser: 'oidcStore/oidcUser',
      Capabilities: 'capablitiesStore/Capabilities',
      UserConfigurationLoadAttempted: 'userConfigurationStore/LoadAttempted',
      UserRole: 'userConfigurationStore/Role',
      SystemRoles: 'rolesStore/Dictionary',
    }),
    onLandingPage() {
      return ['/'].includes(window.location.pathname);
    },
    landingPageStyle() {
      return this.onLandingPage ? '' : 'container-between-stickies';
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      showAppTools: false,
    };
  },
  destroyed() {
    window.removeEventListener('vuexoidc:accessTokenExpired', null);
    window.removeEventListener('iris:share-capability', null);
    window.removeEventListener('iris:start-tour', null);
  },
  methods: {
    ...mapActions({
      oidcAuthenticate: 'oidcStore/authenticateOidc',
      oidcClearStaleState: 'oidcStore/clearStaleState',
      logout: 'oidcStore/signOutOidc',
      LoadUserConfiguration: 'userConfigurationStore/Load',
    }),
    accessTokenExpired() {
      this.oidcAuthenticate();
    },
  },
  mounted() {
    window.addEventListener('vuexoidc:accessTokenExpired', this.accessTokenExpired);
    this.oidcClearStaleState();
  },
  updated() {
    const routePaths = this.$router.options.routes.map((p) => p.path);
    this.showAppTools = !routePaths.includes(window.location.pathname);
  },
  name: 'Landing',
  watch: {
    oidcAccessToken(newToken) {
      const custEvent = new CustomEvent('iris:oidc-token-refreshed', {
        detail: {
          oidcAccessToken: newToken,
        },
      });
      window.dispatchEvent(custEvent);
    },
    oidcIsAuthenticated(newV) {
      if (!newV) {
        return;
      }
      if (this.UserRole || this.UserConfigurationLoadAttempted) {
        return;
      }
      this.LoadUserConfiguration();
    },

  },
};
</script>
