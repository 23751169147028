import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const M_SET_SEARCH = 'SetSearch';
const SV_SEARCH = 'searches';

const actions = {
};

const getters = {
  Searches: GettersUtility.get(SV_SEARCH),
};

const mutations = {
  [M_SET_SEARCH]: MutationsUtility.set(SV_SEARCH),
};

const state = {
  [SV_SEARCH]: [
    {
      subHeaderColor: '#69b3e3',
      headerColor: '#003a63',
      backgroundColor: 'rgba(105, 179, 227, .71)',
      name: 'Enterprise Search',
      sections: [
        {
          headerText: 'Search',
          subHeaderText: 'Enterprise',
          items: [
            'Enterprise Name',
            'Registration Number',
          ],
        },
        {
          headerText: 'Address',
          subHeaderText: 'Enterprise',
          items: [
            'Place ID or SEOID',
          ],
        },
        {
          headerText: 'Nearby',
          subHeaderText: 'Enterprise',
          items: [
            'Latitude',
            'Longitude',
            'Radius',
          ],
        },
        {
          headerText: 'Location',
          subHeaderText: 'Enterprise',
          items: [
            'Latitude',
            'Longitude',
          ],
        },
        {
          headerText: 'Land Parcel',
          subHeaderText: 'Enterprise',
          items: [
            'Cadastral Reference',
            'Ipicode',
          ],
        },
      ],
    },
    {
      subHeaderColor: '#A8509F',
      headerColor: '#003a63',
      backgroundColor: 'rgba(168, 80, 159, .62)',
      name: 'Enterprise Details',
      sections: [
        {
          headerText: 'Business',
          subHeaderText: 'Details',
          items: [
            'Registration Number',
          ],
        },
        {
          headerText: 'Director',
          subHeaderText: 'Details',
          items: [
            'Registration Number',

          ],
        },
        {
          headerText: 'Auditor',
          subHeaderText: 'Details',
          items: [
            'Registration Number',
          ],
        },
        {
          headerText: 'History',
          subHeaderText: 'Details',
          items: [
            'Registration Number',
          ],
        },
      ],
    },
    {
      subHeaderColor: '#59BC6C',
      headerColor: '#003a63',
      backgroundColor: 'rgba(89, 188, 108, .71)',
      name: 'Director Search',
      sections: [
        {
          headerText: 'Search',
          subHeaderText: 'Director',
          items: [
            'Director ID Number',
          ],
        },
      ],
    },
    {
      subHeaderColor: '#2D8FA8',
      headerColor: '#003a63',
      backgroundColor: '#ADDBE5',
      name: 'Director Details',
      sections: [
        {
          headerText: 'Directorship',
          subHeaderText: 'Details',
          items: [
            'Director ID Number',
          ],
        },
        {
          headerText: 'Enterprise',
          subHeaderText: 'Details',
          items: [
            'Registration Number',
            'Director ID Number',
          ],
        },
      ],
    },
  ],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
