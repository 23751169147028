<template>
  <div>
    <b-navbar
      fixed="top"
      tag="header"
      toggleable="lg"
      type="light"
      variant="light"
      class="header-height"
    >
      <b-navbar-brand>
        <b-link href="/home" class="navbar-brand">
          <img
            alt="AfriGIS Logo"
            class="logo"
            src="@/assets/AGLogoBlueCropped_210x37.png"
            width="210px"
            height="37px"
          />
        </b-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"  />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-if="routeLinks.length" text="Capabilities" right>
            <b-dropdown-item
              class="menuItems"
              :key="`rl${index}`"
              :to="item.to"
              v-for="(item, index) in routeLinks"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right text="Menu">
            <b-dropdown-item to="/home">
              Home
            </b-dropdown-item>
            <b-dropdown-item
              target="_blank"
              href="/contact-us">
              Contact an Expert
            </b-dropdown-item>
            <b-dropdown-item
              :key="`menu-item-${index}`"
              :to="menu.to"
              v-for="(menu, index) in menuList"
            >
              {{ menu.name }}
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.logout-modal v-if="!!oidcUser">
              Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
      <logout-confirm-modal
        @logout="logout"
        v-if="!!oidcUser"
      />
  </div>
  </template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LogoutConfirmModal from '@/components/LogoutConfirmModal.vue';

import {
  BCollapse,
  BDropdownItem,
  BLink,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavItemDropdown,
} from 'bootstrap-vue';

export default {
  components: {
    BCollapse,
    BDropdownItem,
    BLink,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItemDropdown,
    LogoutConfirmModal,
  },
  computed: {
    ...mapGetters({
      Capabilities: 'capablitiesStore/Capabilities',
      UserRole: 'userConfigurationStore/Role',
      SystemRoles: 'rolesStore/Dictionary',
      oidcUser: 'oidcStore/oidcUser',
    }),
    routeLinks() {
      if (!this.Capabilities || !this.Capabilities.length) {
        return [];
      }
      return this.Capabilities.filter((l) => l.isActive).map((l) => ({
        name: l.menuTitle,
        to: l.linkTo,
      }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    menuList() {
      const menu = [
        {
          name: 'Reporting',
          to: '/reporting',
          roles: [
            this.SystemRoles.SuperAdministrator,
            this.SystemRoles.Administrator,
          ].map((sr) => sr.value),
        },
        {
          name: 'User Management',
          to: '/user-management',
          roles: [
            this.SystemRoles.SuperAdministrator,
            this.SystemRoles.Administrator,
          ].map((sr) => sr.value),
        },
      ];
      return menu
        .filter((m) => m.roles.some((r) => r === this.UserRole))
        .map((m) => ({
          name: m.name,
          to: m.to,
        }));
    },
  },
  methods: {
    ...mapActions({
      logout: 'oidcStore/signOutOidc',
      oidcAuthenticate: 'oidcStore/authenticateOidc',
    }),
  },
  name: 'AppHeader',
};
</script>

  <style>
  .header-height {
    min-height: 58px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .logo {
    width: 210px;
  }
  @media screen and (max-width: 328px) {
    .logo {
      width: 190px;
    }
  }
  @media screen and (max-width: 500px) {
    .menuItems {
      margin: 0.5em!important;
    }
  }
  </style>
