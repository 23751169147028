<template>
  <b-modal
    @ok="$emit('logout')"
    id="logout-modal"
    title="Ready to Leave?"
  >
    <p>Click Ok to end your current session.</p>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmLogout',
};
</script>

<style></style>
