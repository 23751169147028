import Axios from 'axios';
import { GettersUtility, MutationsUtility, ModuleUtilities } from '@afrigis/vuex-utilities';

import Constants from '@/store/Constants';

const {
  MessagingProxyStore: MPS,
  MUTATIONS,
  STATE_VARS,
  VALIDATIONS,
} = Constants;

const ENDPOINT = `${process.env.VUE_APP_ROOT_API}/proxies/messaging/api/messaging`;

const initialState = () => ({
  [STATE_VARS.IS_LOADING]: false,
  [STATE_VARS.IS_LOADING_FEEDBACK]: false,
});

const STATE_PROPS_TO_EXPOSE = [
  STATE_VARS.IS_LOADING,
  STATE_VARS.IS_LOADING_FEEDBACK,
];

const actions = {
  [MPS.Actions.SendFeedback]: async (context, payload) => {
    if (!payload) {
      throw new Error(VALIDATIONS.PAYLOAD_REQUIRED);
    }
    const requestObj = {
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      data: payload,
      method: 'POST',
      url: `${ENDPOINT}/feedback`,
    };

    try {
      context.commit(MUTATIONS.SET_IS_LOADING_FEEDBACK, true);
      const { data } = await Axios(requestObj);
      if (![200, 201].includes(data.code)) {
        throw new Error(data.message);
      }
      context.commit(MPS.Mutations.SetFeedback, data.result);
    } catch (error) {
      const errorMessage = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorMessage);
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING_FEEDBACK, false);
    }
  },
  [MPS.Actions.SendContactUsForm]: async (context, payload) => {
    if (!payload) {
      throw new Error(VALIDATIONS.PAYLOAD_REQUIRED);
    }
    const requestObj = {
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      data: payload,
      method: 'POST',
      url: `${ENDPOINT}/contact-us-form`,
    };

    try {
      context.commit(MUTATIONS.SET_IS_LOADING, true);
      const { data } = await Axios(requestObj);
      if (![200, 201].includes(data.code)) {
        throw new Error(data.message);
      }
      context.commit(MPS.Mutations.SetContactUsForm, data.result);
    } catch (error) {
      const errorMessage = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorMessage);
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING, false);
    }
  },
};

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.RESET]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
