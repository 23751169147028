const loco = window.location;

const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;

export default {
  authority: process.env.VUE_APP_IDENTITY_SERVER_URL,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  clientId: process.env.VUE_APP_IDENTITY_CLIENT_ID,
  redirectUri: `${appRootUrl}oidc-callback`,
  responseType: process.env.VUE_APP_IDENTITY_RESPONSE_TYPE,
  silentRedirectUri: `${appRootUrl}oidc-silent-renew`,
  scope: process.env.VUE_APP_IDENTITY_SCOPE,
  post_logout_redirect_uri: appRootUrl.substring(0, appRootUrl.length - 1),
};
