import '@/set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { ModalPlugin } from 'bootstrap-vue';

// import { BootstrapVue, LayoutPlugin } from 'bootstrap-vue';
import VueGtag from 'vue-gtag';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/common.css';
import VueGtm from '@gtm-support/vue2-gtm';
import Axios from 'axios';

// Vue.use(BootstrapVue);
// Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_ID,
  },
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  compatibility: false,
  nonce: '2726c7f26c',
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Axios.interceptors.request
  .use(
    (config) => {
      const authorisationHeader = `Bearer ${store.getters['oidcStore/oidcAccessToken']}`;
      const { headers } = config;
      headers.Authorization = authorisationHeader;
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

Axios.interceptors.response
  .use(
    (response) => {
      if (response.data.code === 303) {
        router.push({ name: 'AccountDisabled', props: { message: response.data.message } });
        return Promise.reject(response.data);
      }
      if ([401, 403].some((code) => code === response.data.code)) {
        router.push({ name: 'NotAuthorized' });
        return Promise.reject(response.data);
      }
      return response;
    },
    (error) => {
      if (error.response.status
        && [401, 403].some((code) => code === error.response.status)) {
        router.push({ name: 'NotAuthorized' });
      } else if (error.response.status && error.response.status >= 500) {
        router.push({ name: 'Error' });
      }
      return Promise.reject(error);
    },
  );

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
        },
      });
    },
    router,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
