<template>
  <b-navbar fixed="bottom" tag="footer" type="primary" variant="primary">
    <b-navbar-nav class="d-none d-lg-flex">
      <b-nav-text class="text-light">
        Copyright {{ currentYear }} &copy;
      </b-nav-text>
      <b-nav-item
        href="https://www.afrigis.co.za/"
        link-classes="text-light"
        target="_blank"
      >
        AfriGIS (Pty) Ltd
      </b-nav-item>
      <b-nav-text class="text-light">
        {{ getVersionNumber }}
      </b-nav-text>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto d-none d-lg-flex">
      <b-nav-item :href="legalHref" link-classes="text-light" target="_blank">
        Terms and Conditions | Licensing and Legal | Privacy Policy
      </b-nav-item>
      <b-nav-item
        href="/contact-us"
        link-classes="text-light"
        target="_blank"
      >
        Contact AfriGIS
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="d-lg-none">
      <b-nav-item
        href="https://www.afrigis.co.za/"
        link-classes="text-light"
        target="_blank"
      >
        &copy;
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="d-lg-none ml-auto">
      <b-nav-item :href="legalHref" link-classes="text-light" target="_blank">
        <b-icon-files />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      class="d-lg-none ml-auto"
      v-if="showAppTools">
      <b-nav-item
        @click="tour"
          link-classes="text-light">
        <b-icon-info />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      class="d-lg-none ml-auto"
      v-if="showAppTools">
       <b-nav-item
        @click="share"
        link-classes="text-light">
        <b-icon-share />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="d-lg-none ml-auto">
      <b-nav-item
        href="/contact-us"
        link-classes="text-light"
        target="_blank"
      >
        <b-icon-telephone />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import {
  BIconFiles,
  BIconInfo,
  BIconShare,
  BIconTelephone,
  BNavbar,
  BNavbarNav,
  BNavItem,
  BNavText,
} from 'bootstrap-vue';

export default {
  components: {
    BIconFiles,
    BIconInfo,
    BIconShare,
    BIconTelephone,
    BNavbar,
    BNavbarNav,
    BNavItem,
    BNavText,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    legalHref: () => 'https://www.afrigis.co.za/legal/',
    getVersionNumber() {
      return process.env.VUE_APP_VERSION.toLowerCase() === 'v'
        ? process.env.VUE_APP_VERSION
        : `v${process.env.VUE_APP_VERSION}`;
    },

  },
  methods: {
    share() {
      const custEvent = new CustomEvent('iris:share-capability');
      window.dispatchEvent(custEvent);
    },
    tour() {
      const custEvent = new CustomEvent('iris:start-tour');
      window.dispatchEvent(custEvent);
    },
  },
  props: {
    showAppTools: {
      type: Boolean,
      required: true,
    },
  },
  name: 'AppFooter',
};
</script>

<style>
</style>
