import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const M_SET_PROPERTY = 'SetProperty';
const SV_PROPERTY = 'properties';

const actions = {
};

const getters = {
  Properties: GettersUtility.get(SV_PROPERTY),
};

const mutations = {
  [M_SET_PROPERTY]: MutationsUtility.set(SV_PROPERTY),
};

const state = {
  [SV_PROPERTY]: [
    {
      subHeaderColor: '#A8509F',
      headerColor: '#003a63',
      backgroundColor: '#69b3e3',
      name: null,
      sections: [
        {
          headerText: 'Title Deed',
          subHeaderText: 'Ownership',
          items: [
            'Title Deed',
            'Deeds Office',
          ],
        },
        {
          headerText: 'Scheme',
          subHeaderText: 'Ownership',
          items: [
            'Sect, Scheme Name',
            'Sect, Scheme Number',
            'Year established',
            'Unit Number',
            'Deeds Office',
          ],
        },
        {
          headerText: 'Location',
          subHeaderText: 'Ownership',
          items: [
            'Latitude',
            'Longitude',
          ],
        },
        {
          headerText: 'Land Parcel',
          subHeaderText: 'Ownership',
          items: [
            'Cadastral Reference',
            'Ipicode',
          ],
        },
        {
          headerText: 'Farm',
          subHeaderText: 'Ownership',
          items: [
            'Farm Name',
            'Farm Number',
            'Portion Number',
            'Registration Division',
            'Deeds Office',
          ],
        },
        {
          headerText: 'Erf',
          subHeaderText: 'Ownership',
          items: [
            'Township',
            'Erf Number',
            'Portion Number',
            'Deeds Office',
          ],
        },
        {
          headerText: 'Address',
          subHeaderText: 'Ownership',
          items: [
            'Place ID or SEO ID',
          ],
        },
        {
          headerText: 'Person',
          subHeaderText: '',
          items: [
            'ID Number (Registered to the property)',
          ],
        },
      ],
    },
  ],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
