import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const MAP_BACKGROUNDS_IMAGE_URL = require('@/assets/images/mapBackground/LSI_MapBackgrounds_400x400.webp');
const MAP_BACKGROUNDS_IMAGE_URL_ALTERNATE = require('@/assets/images/mapBackground/LSI_MapBackgrounds_400x400_alternate.webp');

const MAP_LAYERS_URL = require('@/assets/images/mapLayers/LSI_MapLayers_400x400.webp');
const MAP_LAYERS_URL_ALTERNATE = require('@/assets/images/mapLayers/LSI_MapLayers_400x400_alternate.webp');

const ADDRESS_SEARCH_IMAGE_URL = require('@/assets/images/addressSearch/LSI_Search_400x400.webp');
const ADDRESS_SEARCH_IMAGE_URL_ALTERNATE = require('@/assets/images/addressSearch/LSI_Search_400x400_alternate.webp');

const ROUTES_IMAGE_URL = require('@/assets/images/route/LSI_Routes_400x400.webp');
const ROUTES_IMAGE_URL_ALTERNATE = require('@/assets/images/route/LSI_Routes_400x400_alternate.webp');

const DELIVERY_IMAGE_URL = require('@/assets/images/deliveryAddressFormatting/LSI_Delivery_400x400.webp');
const DELIVERY_IMAGE_URL_ALTERNATE = require('@/assets/images/deliveryAddressFormatting/LSI_Delivery_400x400_alternate.webp');

const WEATHER_IMAGE_URL = require('@/assets/images/weather/LSI_Weather_400x400.webp');
const WEATHER_IMAGE_URL_ALTERNATE = require('@/assets/images/weather/LSI_Weather_400x400_alternate.webp');

const JOURNEY_PLANNER_IMAGE_URL = require('@/assets/images/optimalJourneyPlanner/LSI_MultiAddressRoute_400x400.webp');
const JOURNEY_PLANNER_IMAGE_URL_ALTERNATE = require('@/assets/images/optimalJourneyPlanner/LSI_MultiAddressRoute_400x400_alternate.webp');

const TRAVELBANDS_IMAGE_URL = require('@/assets/images/travelBands/LSI_TravelBands_400px.webp');
const TRAVELBANDS_IMAGE_URL_ALTERNATE = require('@/assets/images/travelBands/H_LSI_TravelBands_400px.webp');

const TRIPCOST_IMAGE_URL = require('@/assets/images/tripCost/LS_TCC_Light.webp');
const TRIPCOST_IMAGE_URL_ALTERNATE = require('@/assets/images/tripCost/LS_TCC_Dark.webp');

const REVERSE_GEOCODE_IMAGE_URL = require('@/assets/images/reverseGeocode/AG_RC_2_Light.webp');
const REVERSE_GEOCODE_IMAGE_URL_ALTERNATE = require('@/assets/images/reverseGeocode/AG_RC_2_Dark.webp');

const AREA_DIST_IMAGE_URL = require('@/assets/images/mapTools/AG_MT_Light.webp');
const AREA_DIST_IMAGE_URL_ALTERNATE = require('@/assets/images/mapTools/AG_MT_Dark.webp');

const HEAT_MAP_LAYERS_URL = require('@/assets/images/heatMap/AG_HeatMap_Line.webp');
const HEAT_MAP_LAYERS_URL_ALTERNATE = require('@/assets/images/heatMap/AG_HeatMap_Dark.webp');

const THEMATIC_MAP_LAYERS_URL = require('@/assets/images/thematicMap/AG_ThematicMaps_Line.webp');
const THEMATIC_MAP_LAYERS_IMAGE_URL_ALTERNATE = require('@/assets/images/thematicMap/AG_ThemMaps_Dark.webp');

const PLACE_DETAILS_URL = require('@/assets/images/placeDetails/LSI_PlaceDetails_400x400.webp');
const PLACE_DETAILS_URL_ALTERNATE = require('@/assets/images/placeDetails/LSI_PlaceDetails_400x400_alternate.webp');

const PROPERTY_OWNERSHIP_DEEDS_URL = require('@/assets/images/propertyOwnershipDeeds/LSI_PropOwner_400x400.webp');
const PROPERTY_OWNERSHIP_DEEDS_URL_ALTERNATE = require('@/assets/images/propertyOwnershipDeeds/LSI_PropOwner_400x400_alternate.webp');

const ENTERPRISE_DETAILS_URL = require('@/assets/images/enterpriseDetails/LSI_Enterprise_400x400.webp');
const ENTERPRISE_DETAILS_URL_ALTERNATE = require('@/assets/images/enterpriseDetails/LSI_Enterprise_400x400_alternate.webp');

// const GENERICPLACEHOLDER_IMAGE_URL = require('@/assets/images/GenericPlaceholder_600x600.webp');

const M_SET_CAPABILITIES = 'SetCapabilities';

const SV_CAPABILITIES = 'capabilities';

const actions = {
};

const getters = {
  Capabilities: GettersUtility.get(SV_CAPABILITIES),
};

const mutations = {
  [M_SET_CAPABILITIES]: MutationsUtility.set(SV_CAPABILITIES),
};

const state = {
  [SV_CAPABILITIES]: [
    {
      imageUrl: MAP_BACKGROUNDS_IMAGE_URL,
      imageUrlAlternate: MAP_BACKGROUNDS_IMAGE_URL_ALTERNATE,
      linkTo: '/map-background-information',
      readmoreLink: '/map-background-information',
      title: 'Explore Map Backgrounds',
      menuTitle: 'Map Backgrounds',
      description:
        'What is a map background? It is the canvas used to display data on. Your map background gives geographical context and enables you to position yourself in terms of scale and location.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: MAP_LAYERS_URL,
      imageUrlAlternate: MAP_LAYERS_URL_ALTERNATE,
      linkTo: '/map-layers-information',
      readmoreLink: '/map-layers-information',
      title: 'Discover Map Layers',
      menuTitle: 'Map Layers',
      description:
        'Map layers are where the magic happens. As the world becomes smarter, data is the key to your competitive advantage. Map layers add insights to your map, enabling you to make informed decisions.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: ADDRESS_SEARCH_IMAGE_URL,
      imageUrlAlternate: ADDRESS_SEARCH_IMAGE_URL_ALTERNATE,
      linkTo: '/address-search-information',
      readmoreLink: '/address-search-information',
      title: 'Try Address Search',
      menuTitle: 'Address Search',
      description:
        'Find out exactly where you are going. Get address matches based on the text you type into the Address Search field. The address results are refined as you type more characters in the search field.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: ROUTES_IMAGE_URL,
      imageUrlAlternate: ROUTES_IMAGE_URL_ALTERNATE,
      linkTo: '/route-information',
      readmoreLink: '/route-information',
      title: 'Check Route',
      menuTitle: 'Routes',
      description:
        'Map your route from point A to point B. Use refined address search with verified and validated address data. Our address data is SABS and ISO compliant with over 50 million searchable addresses.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: DELIVERY_IMAGE_URL,
      imageUrlAlternate: DELIVERY_IMAGE_URL_ALTERNATE,
      linkTo: '/delivery-information',
      readmoreLink: '/delivery-information',
      title: 'Test Delivery Address Formatting',
      menuTitle: 'Address Formatting',
      description:
        'Increase your chances of a successful delivery with our Delivery API. This interface reformats an irregular address into a standard address format. Making it much easier to read and find.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: WEATHER_IMAGE_URL,
      imageUrlAlternate: WEATHER_IMAGE_URL_ALTERNATE,
      linkTo: '/weather-information',
      readmoreLink: '/weather-information',
      title: 'Unlock Weather Insights',
      menuTitle: 'Weather Insights',
      description: 'You don’t need a crystal ball to reveal weather patterns. We can help you interpret weather-based data to unlock valuable insights to help manage risk and exposure.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: JOURNEY_PLANNER_IMAGE_URL,
      imageUrlAlternate: JOURNEY_PLANNER_IMAGE_URL_ALTERNATE,
      linkTo: '/journey-planner-information',
      readmoreLink: '/journey-planner-information',
      title: 'Test drive Optimal Journey Planner',
      menuTitle: 'Journey Planner',
      description: 'Plan your most efficient trip with our Optimal Journey Planner. It takes into account data like your delivery priorities, number of stops, traffic and tollgates.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: TRAVELBANDS_IMAGE_URL,
      imageUrlAlternate: TRAVELBANDS_IMAGE_URL_ALTERNATE,
      linkTo: '/travel-bands-information',
      readmoreLink: '/travel-bands-information',
      title: 'View Travel Time Bands',
      menuTitle: 'Travel Time Bands',
      description: 'Discover how close you are to the nearest school, hospital, or other essential services. Calculate the average travel time and distance to important facilities with Travel Time Bands.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: TRIPCOST_IMAGE_URL,
      imageUrlAlternate: TRIPCOST_IMAGE_URL_ALTERNATE,
      linkTo: '/trip-cost-information',
      readmoreLink: '/trip-cost-information',
      title: 'Try Trip Cost Calculator',
      menuTitle: 'Trip Cost Calculator',
      description: 'Need to know the trip costs and route fees for your next long-distance trip? Simply use the Trip Cost Calculator to get your answer.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: REVERSE_GEOCODE_IMAGE_URL,
      imageUrlAlternate: REVERSE_GEOCODE_IMAGE_URL_ALTERNATE,
      linkTo: '/coordinate-search-information',
      readmoreLink: '/coordinate-search-information',
      title: 'Perform an Advanced Search',
      menuTitle: 'Coordinate Search',
      description: 'Search for locations by coordinate, browser location or a dropped pin. We make it look simple, but a complex back-end ensures verified validated data. No matter what search method is used.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: AREA_DIST_IMAGE_URL,
      imageUrlAlternate: AREA_DIST_IMAGE_URL_ALTERNATE,
      linkTo: '/map-tools-information',
      readmoreLink: '/map-tools-information',
      title: 'Measure with Distance and Area Calculator',
      menuTitle: 'Distance and Area Calculator',
      description: 'Learn how big an area is or calculate the total distance of a route. These are just a few calculations you can do when we integrate Leaflet map tools into your map solutions.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: THEMATIC_MAP_LAYERS_URL,
      imageUrlAlternate: THEMATIC_MAP_LAYERS_IMAGE_URL_ALTERNATE,
      linkTo: '/thematic-maps-layer-information',
      readmoreLink: '/thematic-maps-layer-information',
      title: 'Experience Thematic Maps',
      menuTitle: 'Thematic Map Layers',
      description: 'Find out how data science can display relevant statistical data on a themed map, so you see more than just a map. You see answers to problems you did not even know you had.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: HEAT_MAP_LAYERS_URL,
      imageUrlAlternate: HEAT_MAP_LAYERS_URL_ALTERNATE,
      linkTo: '/heat-map-layer-information',
      readmoreLink: '/heat-map-layer-information',
      title: 'Experience Spatial Heat Maps',
      menuTitle: 'Heat Map Layers',
      description: 'See complex information displayed in a way that reveals answers to your questions. Heat Maps visualise many different data points to uncover a clearer picture',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: PLACE_DETAILS_URL,
      imageUrlAlternate: PLACE_DETAILS_URL_ALTERNATE,
      linkTo: '/place-details-information',
      readmoreLink: '/place-details-information',
      title: 'Place Details',
      menuTitle: 'Place Details',
      description: `Looking for detailed address information about a location?
      Place Details takes an address and breaks it up into the different address types/details.`,
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: PROPERTY_OWNERSHIP_DEEDS_URL,
      imageUrlAlternate: PROPERTY_OWNERSHIP_DEEDS_URL_ALTERNATE,
      linkTo: '/property-ownership-deeds-information',
      readmoreLink: '/property-ownership-deeds-information',
      title: 'Get Property Ownership & Deeds data',
      menuTitle: 'Property Ownership & Deeds',
      description: 'Do you need more information about a specific property and its ownership? Look no further. Get enriched, reliable data and find out exactly to whom properties are registered.',
      isAuth: true,
      isActive: true,
    },
    {
      imageUrl: ENTERPRISE_DETAILS_URL,
      imageUrlAlternate: ENTERPRISE_DETAILS_URL_ALTERNATE,
      linkTo: '/enterprise-details-information',
      readmoreLink: '/enterprise-details-information',
      title: 'Investigate Enterprise Details',
      menuTitle: 'Enterprise Details',
      description: 'Find out what you need to know about a business. Does the company I`m dealing with really exist? Which entreprises are located in an area or location? See who the directors of a corporation are. ',
      isAuth: true,
      isActive: true,
    },
  ],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
