export default {
  ACTIONS: {
    CREATE: 'Create',
    DELETE: 'Delete',
    EDIT: 'Edit',
    LOAD: 'Load',
  },

  GETTERS: {
    DATA: 'Data',
    IS_LOADING: 'IsLoading',
    IS_LOADING_FEEDBACK: 'IsloadingFeedback',
  },

  MUTATIONS: {
    SET_DATA: 'SetData',
    SET_IS_LOADING: 'SetIsLoading',
    SET_IS_LOADING_FEEDBACK: 'SetIsloadingFeedback',
    RESET: 'Reset',
  },

  STATE_VARS: {
    DATA: 'data',
    IS_LOADING: 'isLoading',
    IS_LOADING_FEEDBACK: 'IsloadingFeedback',
  },

  VALIDATIONS: {
    PAYLOAD_REQUIRED: '"Payload" parameter is required.',
  },

  MessagingProxyStore: {
    Actions: {
      SendFeedback: 'SendFeedback',
      SendContactUsForm: 'SendContactUsForm',
    },
    Mutations: {
      SetFeedback: 'SetFeedback',
      SetContactUsForm: 'SetContactUsForm',
    },
    Name: 'MessagingProxyStore',
  },
};
